<template>
  <el-main>
    <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
      <el-tabs v-model="step">
        <el-tab-pane label="基础信息" name="1">
          <el-form-item label="服务分类：" prop="classify_id">
            <el-cascader
              v-model="addForm.classify_id"
              :props="{ value: 'id', label: 'name', children: '_child' }"
              placeholder="请选择或搜索"
              clearable
              filterable
              :options="serviceClassify"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="排序：">
            <el-input v-model="addForm.sort"></el-input>
            <p>数字越大，排名越靠前；如为空，排序默认认为添加时间越近越靠前</p>
          </el-form-item>
          <el-form-item label="服务名称：" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="推广奖励：">
            <el-input v-model="addForm.ratio"></el-input>
            %
          </el-form-item>
          <el-form-item label="服务状态：">
            <el-radio-group v-model="addForm.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="服务图片：" prop="picture">
            <ReadyUploadSource
              @getSource="getServiceImg"
              @changeOrder="changeOrder"
              @removeThis="removeImageThis"
              :isMany="true"
              :manyPath="addForm.picture"
              :isManyMax="10"
              :maxSize="1024 * 1024"
            ></ReadyUploadSource>
            <p>建议图片尺寸750px*750px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
          </el-form-item>

          <el-form-item label="服务信息：">
            <div class="service-info-box">
              <div class="service-info-item" v-for="(item, i) in addForm.content" :key="i">
                <div class="service-title">
                  <p>服务标题</p>
                  <el-input v-model="item.title" @blur="checkServiceInfoForm" placeholder="请输入服务标题"></el-input>
                </div>
                <div class="service-content">
                  <p>服务内容</p>
                  <div class="service-content-item">
                    <div class="el-input-box" v-for="(detail, y) in item.detail" :key="y">
                      <el-input v-model="detail.content" @blur="checkServiceInfoForm" placeholder="请输入服务内容"></el-input>
                      <i class="el-icon-circle-close" @click="removeServiceContent(i, y)"></i>
                    </div>
                    <p @click="addServiceContent(i)">+添加内容</p>
                  </div>
                </div>
                <div v-if="i > 0" class="close" @click="removeService(i)">
                  <i class="el-icon-circle-close"></i>
                </div>
                <p v-show="item.is_error" style="color: #f56c6c;font-size:12px;">请将服务信息内容填写完整</p>
              </div>
            </div>
            <el-button style="margin-top: 15px" type="primary" class="el-icon-plus" size="small" @click="addService">增加服务信息选项</el-button>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="服务详情" name="2">
          <el-form-item label="服务详情：" prop="details">
            <RichText :richTxt="addForm.details" @soninfo="val => (addForm.details = val)"></RichText>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <Preservation @preservation="serviceSubmit">
      <router-link to="/service/list" slot="return" v-if="addForm.id" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import _ from 'lodash';
import ReadyUploadSource from '@/components/readyUploadSource';
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    ReadyUploadSource,
    RichText,
    Preservation,
  },
  data() {
    return {
      step: '1',
      serviceClassify: [],
      addForm: {
        classify_id: [],
        sort: '',
        name: '',
        picture: [],
        details: '',
        ratio: '',
        status: 1,
        content: [
          {
            title: '',
            is_error: !0,
            detail: [
              {
                content: '',
              },
              {
                content: '',
              },
            ],
          },
        ],
      },
      rules: {
        classify_id: {
          type: 'array',
          required: true,
          message: '请选择服务分类',
          trigger: 'blur',
        },
        picture: {
          type: 'array',
          required: true,
          message: '请添加服务图片',
          trigger: 'blur',
        },
        name: {
          required: true,
          message: '请填写服务名称',
          trigger: 'blur',
        },
        details: {
          required: true,
          message: '请填写服务详情',
          trigger: 'blur',
        },
      },
    };
  },
  async created() {
    await this.$axios
      .post(this.$api.samecity.service.classifyList, {
        is_show: this.is_show,
        name: this.name,
      })
      .then(res => {
        if (res.code == 0) {
          this.serviceClassify = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    if (this.$route.query.id) {
      this.addForm.id = this.$route.query.id;
      this.getServiceInfo();
    }
  },
  methods: {
    //获取服务图片
    getServiceImg(val) {
      let arr = [...this.addForm.picture];
      for (let i in val) arr.push(val[i].path);
      this.addForm.picture = arr;
    },
    // 拖动重新赋值数据
    changeOrder(val) {
      this.addForm.picture = val;
    }, 
    // 删除单张图片
    removeImageThis(index) {
      this.addForm.picture.splice(index, 1);
    },
    //添加服务信息
    addService() {
      this.addForm.content.push({
        title: '',
        is_error: !0,
        detail: [
          {
            content: '',
          },
          {
            content: '',
          },
        ],
      });
    },
    //添加服务信息内容
    addServiceContent(i) {
      this.addForm.content[i].detail.push({
        content: '',
      });
    },
    //删除服务信息内容
    removeServiceContent(i, y) {
      this.$confirm('确认要删除服务信息内容吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addForm.content[i].detail.splice(y, 1);
      });
    },
    //删除服务信息
    removeService(i) {
      this.$confirm('确认要删除服务信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addForm.content.splice(i, 1);
      });
    },
    //校验服务信息表单
    checkServiceInfoForm() {
      let addForm = this.addForm;
      addForm.content.map(item => {
        if (item.title) {
          item.detail.map(now => {
            if (!now.content) {
              item.is_error = !0;
            } else {
              item.is_error = !1;
            }
          });
        } else {
          item.is_error = !0;
        }
      });
    },
    //获取服务详情
    getServiceInfo() {
      this.$axios
        .post(this.$api.samecity.service.serviceInfo, {
          id: this.addForm.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result;
            let addForm = this.addForm;
            let serviceClassify = this.serviceClassify;
            let classify_id_arr = [];
            let content = [];
            addForm.sort = info.sort || '';
            addForm.name = info.name;
            addForm.picture = info.pictures;
            addForm.details = info.details;
            addForm.ratio = info.ratio;
            addForm.status = info.status;
            //处理服务分类
            for (let i in serviceClassify) {
              if (serviceClassify[i].id == info.classify_id) {
                classify_id_arr.push(info.classify_id);
              }
              for (let y in serviceClassify[i]._child) {
                if (serviceClassify[i]._child[y].id == info.classify_id) {
                  classify_id_arr.push(serviceClassify[i].id, info.classify_id);
                }
              }
            }
            //处理服务信息
            info.contents.map(item => {
              let obj = {
                title: item.title,
                is_error: !1,
                detail: [],
              };
              item.content.map(item => obj.detail.push({ content: item }));
              content.push(obj);
            });
            addForm.content = content;
            addForm.classify_id = classify_id_arr;
          }
        });
    },
    serviceSubmit() {
      this.$refs.form.validate((valid, val) => {
        if (valid) {
          let addForm = this.addForm;
          //判断服务信息是否填写完整
          if (addForm.content.every(item => item.is_error == !1)) {
            addForm = _.cloneDeep(addForm);
            let content = [];
            addForm.content.map(item => {
              let obj = {
                title: item.title,
                content: [],
              };
              item.detail.map(now => obj.content.push(now.content));
              content.push(obj);
            });
            addForm.content = content;
            if (addForm.classify_id.length) {
              addForm.classify_id.length > 1 ? (addForm.classify_id = addForm.classify_id[1]) : (addForm.classify_id = addForm.classify_id[0]);
            }
            this.$axios.post(addForm.id ? this.$api.samecity.service.editService : this.$api.samecity.service.addService, addForm).then(res => {
              if (res.code == 0) {
                this.$message.success(addForm.id ? '编辑成功' : '添加成功');
                this.$router.push('/service/list');
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.step = '1';
          }
        } else {
          if (val.hasOwnProperty('details') && Object.keys(val).length == 1) {
            this.step = '2';
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background: #fff;
  padding-bottom: 50px;
  .el-form {
    .el-form-item {
      p {
        color: #999;
      }
      .el-input {
        width: 500px;
      }
      .service-info-box {
        border: 1px solid #dcdfe6;
        width: 900px;
      }
      .service-info-item {
        padding: 20px 20px 0 20px;
        border-bottom: 1px solid #dcdfe6;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .close {
          position: absolute;
          z-index: 10;
          top: 10px;
          right: 10px;
          i {
            font-size: 20px;
          }
        }
        &:last-child {
          border: 0;
        }
        p {
          margin-right: 20px;
          line-height: 40px;
        }
        .service-title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .el-input {
            width: 220px;
          }
        }
        .service-content {
          display: flex;
          align-items: flex-start;
          .service-content-item {
            flex: 1;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            .el-input-box {
              position: relative;
              i {
                position: absolute;
                top: -5px;
                right: 5px;
                font-size: 17px;
                cursor: pointer;
              }
            }
            .el-input {
              width: 220px;
              margin-right: 10px;
              margin-bottom: 20px;
            }
            p {
              color: #409eff;
              padding: 0;
              line-height: 40px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>